import styled from "styled-components";
import { Direction } from "../../Theme/MainDirection";

export const SliderContainer = styled.section`

 background-color: #fff;

`;

// ----------------------
export const SliderCardItem = styled.div`
    height: 399px;
    border-radius: 8px;
    padding: 40px;
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;

    article {
      width: 260px;
      max-width: 100%;
      position: relative;
      z-index: 2;
      p {
        padding: 20px 0px;
        color:#000;
        line-height: 24px;
      }
      i {
        padding-bottom: 10px;
        display: block;
        font-style: normal;
        color:#000
      }
      span {
        background: #fff;
        width: 130px;
        border-radius: 80px;
        display: block;
        text-align: center;
        padding: 10px 4px;
        margin-top: 4px;
        color:#000;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 16%);
      }
    }

    &::after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      border-radius: 8px;
      transition: background 0.3s ease-in-out;
    }
    &:hover {
      &::after {
        background: rgba(0, 0, 0, 0.1);
      }
    }

    @media(max-width:414px){
      height: 340px;
    }
  `;

// ----------------------

export const CardImage = styled.div`
    position: absolute;
    bottom: 0;
    ${Direction.Left} :0;
    z-index: 1;
    @media(max-width:414px){
      img{
        width: 200px !important;
        height: auto;
      }
    }
`;