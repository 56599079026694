import React from "react";
import Features from "../../Components/Features/Features";
import InsideMainContent from "../../Components/InsideMainContent/InsideMainContent";
import InsidePageDescription from "../../Components/InsidePageDescription/InsidePageDescription";
import InsidePageTitle from "../../Components/InsidePageTitle/InsidePageTitle";
import OurClients from "../../Components/OurClients/OurClients";
import VisionAndMission from "../../Components/VisionAndMission/VisionAndMission";
import { Text } from "../../Text";

function AboutUs() {
    return (
        <div>
            <InsidePageTitle pageName={Text.navLinks.aboutUs}>{Text.InsidePageTitles.AboutUsPage.TitleName}</InsidePageTitle>

            <InsidePageDescription
                descriptionImage={Text.InsidePageDescription.AboutUsPage.descriptionImage}
                descriptionHead={Text.InsidePageDescription.AboutUsPage.descriptionHead}
                descriptionBody={Text.InsidePageDescription.AboutUsPage.descriptionBody}
            />
            <InsideMainContent>
                <VisionAndMission />
                <Features />
                <OurClients />
            </InsideMainContent>
        </div>
    );
};

export default AboutUs;
