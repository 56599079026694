import { Grid } from '@mui/material';
import React from 'react'
import InsidePageDescription from '../../Components/InsidePageDescription/InsidePageDescription';
import InsidePageTitle from '../../Components/InsidePageTitle/InsidePageTitle'
import { SecurtyPrintingSectionsData } from '../../Components/SecurtyPrintingCategory/SecurtyPrintingData';
import { Text } from "../../Text";
import InsideMainContent from '../../Components/InsideMainContent/InsideMainContent';

const SecurityPrinting = () => {
    return (
        <div>
            <InsidePageTitle pageName={Text.subMenu.securityPrintg}>{Text.MainSliderData[1].cardName}</InsidePageTitle>
            <InsidePageDescription
                descriptionImage={Text.InsidePageDescription.SecurityPrintingPage.descriptionImage}
                descriptionHead={Text.InsidePageDescription.SecurityPrintingPage.descriptionHead}
                descriptionBody={Text.InsidePageDescription.SecurityPrintingPage.descriptionBody}
            />
            <InsideMainContent>
                <Grid container spacing={2}>
                    {SecurtyPrintingSectionsData}
                </Grid>
            </InsideMainContent>

        </div>
    )
}

export default SecurityPrinting
