import { Text } from "../../Text";
import { ButtonBase, Typography } from "@mui/material";
import { NavLink as RouterLink } from "react-router-dom";
import { SliderCardItem, CardImage } from "./MainSliderStyle";
import { motion } from "framer-motion";


export const MainSliderData = Text.MainSliderData.map((item, index) => {

    return (
        <motion.div key={index}
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: (0.3 + index)/2 , duration:0.5 , type: "spring", stiffness:150 }}
        >
            <ButtonBase

                to={item.path}
                component={RouterLink}
                sx={{ width: "100%", textAlign: "inherit" }}
            >
                <SliderCardItem style={{ background: item.cardColor }}>
                    <Typography component="article">
                        <Typography variant="body1" component="i">
                            {item.label}
                        </Typography>
                        <Typography variant="h1">{item.cardName}</Typography>
                        <Typography variant="body1">{item.cardBody}</Typography>
                        <Typography component="span" variant="body1">
                            {item.cardButton}
                        </Typography>
                    </Typography>
                    <CardImage>
                        <img src={item.cardImage} alt={item.cardName} />
                    </CardImage>
                </SliderCardItem>
            </ButtonBase>
        </motion.div>

    );
});
