import React from "react";
import { Text } from "../../Text";
import { Grid, Typography } from "@mui/material";
import { ServiceBoxItem } from "../ServiceCategoryItemDesign/ServiceCategoryItemDesign";
import { motion } from "framer-motion";

/*category slider in home page -------------------------------------------------*/
export const NetworkingSliderData = Text.NetworkingService.map((item, index) => {
    return (
        <motion.div key={index}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: (0.3 + index) / 3, duration: 0.5, type: "tween" }}
        >
            <ServiceBoxItem >
                <img src={item.serviceIcon} alt={item.serviceTitle} />
                <Typography component="article">
                    <Typography variant="body1">{item.serviceLable}</Typography>
                    <Typography variant="h3">{item.serviceTitle}</Typography>
                    <Typography variant="body1">{item.serviceBody} </Typography>
                    <a href={item.serviceURL} target="_blank">
                        {item.serviceURLName}
                    </a>
                </Typography>
            </ServiceBoxItem>
        </motion.div>
    );
});

/*inside page boxes --------------------------------------------------------------*/
export const NetworkingSectionsData = Text.NetworkingService.map((sectionItem, sectionIndex) => {
    return (
        <Grid item xs={12} sm={6} md={4} key={sectionIndex} sx={{ '&>div': { border: '1px solid #ECF1F3' } }}>

            <motion.div
                initial={{ y: 40, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: (0.3 + sectionIndex) / 3, duration: 0.5, type: "spring", stiffness: 200 }}
            >
                <ServiceBoxItem>
                    <img src={sectionItem.serviceIcon} alt={sectionItem.serviceTitle} />
                    <Typography component="article">
                        <Typography variant="body1">{sectionItem.serviceLable}</Typography>
                        <Typography variant="h3">{sectionItem.serviceTitle}</Typography>
                        <Typography variant="body1">{sectionItem.serviceBody} </Typography>
                        <a href={sectionItem.serviceURL} target="_blank">
                            {sectionItem.serviceURLName}
                        </a>
                    </Typography>
                </ServiceBoxItem>
            </motion.div>
        </Grid>
    );
});
