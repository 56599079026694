import React from "react";
import { Fragment } from "react";
import MainSlider from "../../Components/MainSlider/MainSlider";
import CompanyInfo from "../../Components/CompanyInfo/CompanyInfo";
import ServiceCategories from "../../Components/ServiceCategories/ServiceCategories";
import OurClients from "../../Components/OurClients/OurClients";
import { motion } from "framer-motion";

export default function Home() {
    return (
        <Fragment>
            <MainSlider />
            <CompanyInfo />
            <ServiceCategories />
            <OurClients />
        </Fragment>
    );
}
