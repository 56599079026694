import React from "react";
import SmallContainer from "../../Theme/SmallContainer";
import styled from "styled-components";
import { Container, Typography } from "@mui/material";
import { Direction } from "../../Theme/MainDirection";
import customTheme from "../../Theme/theme";
import Brackpoints from "../../Theme/Brackpoints";
import Qute from "../../assets/images/Quote.svg";
import { motion } from "framer-motion";


const InsideDescriptionContainer = styled.div`
  position: relative;
  padding: 40px 0px;

  @media ${Brackpoints.device.md}{
      padding: 91px 0px 80px 0px;
  }

  article{
      position: relative;
      p{
          padding-top: 12px;
          width: 500px;
          max-width: 100%;
          color:#555;
      }

      @media ${Brackpoints.device.lg}{
      &::after{
          content: '';
          width: 47px;
          height: 34px;
          background:url(${Qute}) no-repeat;
          
          position: absolute;
          top: 0;
           ${Direction.Right}:0;
           ${Direction.MarginRight}:-70px;
           margin-top: -30px;

      }
      }
  }
`;
const InsidePageImage = styled.div`
  width: 345px;
  height: 345px;
  border-radius: 50%;
  display: none;
  position: absolute;
  top: 0;
  ${Direction.Left}:0;
  background-color: ${customTheme.palette.secondary.main};
  margin-top: -130px;
  img {
    position: absolute;
    bottom: 0;
    ${Direction.Left}:50%;
    transform: translateX(-50%);
    max-width: 360px;
    height: auto;
  }
  @media ${Brackpoints.device.md} {
    display: block;
  }
`;

function InsidePageDescription(props) {
    return (
        <SmallContainer>
            <Container fixed>
                <InsideDescriptionContainer>
                    <motion.div
                        initial={{ y: "200px", opacity: 0 }}
                        animate={{ y: "-110px", opacity: 1 }}
                        transition={{ delay: 0.2, duration: 0.4, type: "spring", stiffness: 150 }}
                    >
                        <InsidePageImage>
                            <img src={props.descriptionImage} alt={props.descriptionHead} />
                        </InsidePageImage>
                    </motion.div>

                    <motion.div
                        initial={{ y:16, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ delay: 0.3, duration: 0.4, type: "tween" }}
                    >
                        <Typography component="article">
                            <Typography variant="h3">{props.descriptionHead}</Typography>
                            <Typography variant="body1">{props.descriptionBody}</Typography>
                        </Typography>
                    </motion.div>
                </InsideDescriptionContainer>
            </Container>
        </SmallContainer >
    );
}

export default InsidePageDescription;
