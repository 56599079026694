import { Grid } from "@mui/material";
import React from "react";
import InsideMainContent from "../../Components/InsideMainContent/InsideMainContent";
import InsidePageDescription from "../../Components/InsidePageDescription/InsidePageDescription";
import InsidePageTitle from "../../Components/InsidePageTitle/InsidePageTitle";
import { ProgramingSectionsData } from "../../Components/ProgramingCategory/ProgramingData";
import { Text } from "../../Text";
const Programing = () => {
  return (
    <div>
      <InsidePageTitle pageName={Text.subMenu.programing}>
        {Text.MainSliderData[2].cardName}
      </InsidePageTitle>
      <InsidePageDescription
        descriptionImage={
          Text.InsidePageDescription.ProgramingPage.descriptionImage
        }
        descriptionHead={
          Text.InsidePageDescription.ProgramingPage.descriptionHead
        }
        descriptionBody={
          Text.InsidePageDescription.ProgramingPage.descriptionBody
        }
      />

      <InsideMainContent>
        <Grid container spacing={2}>
          {ProgramingSectionsData}
        </Grid>
      </InsideMainContent>

    </div>
  );
};

export default Programing;
