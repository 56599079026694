import React from "react";
import InsidePageTitle from "../../Components/InsidePageTitle/InsidePageTitle";
import InsidePageDescription from '../../Components/InsidePageDescription/InsidePageDescription';
import InsideMainContent from '../../Components/InsideMainContent/InsideMainContent';

import { Text } from "../../Text";
import { Grid } from "@mui/material";
import { NetworkingSectionsData } from "../../Components/NetworkCategory/NetworkData";
const Networking = () => {
    return (
        <div>
            <InsidePageTitle pageName={Text.subMenu.networking}>
                {Text.MainSliderData[3].cardName}
            </InsidePageTitle>
            <InsidePageDescription
                descriptionImage={
                    Text.InsidePageDescription.NetworkingPage.descriptionImage
                }
                descriptionHead={
                    Text.InsidePageDescription.NetworkingPage.descriptionHead
                }
                descriptionBody={
                    Text.InsidePageDescription.NetworkingPage.descriptionBody
                }
            />

            <InsideMainContent>
                <Grid container spacing={2}>
                    {NetworkingSectionsData}
                </Grid>
            </InsideMainContent>
        </div>
    );
};

export default Networking;
