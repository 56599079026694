/*Main Slider Images -------------------------------*/
import cardImage_1 from "./assets/images/cardImage_1.png";
import cardImage_2 from "./assets/images/cardImage_2.png";
import cardImage_3 from "./assets/images/cardImage_3.png";
import cardImage_4 from "./assets/images/cardImage_4.png";

/*Features Icons -------------------------------*/
import FeatureIcon_1 from "./assets/images/featureIcon_1.svg";
import FeatureIcon_2 from "./assets/images/featureIcon_2.svg";
import FeatureIcon_3 from "./assets/images/featureIcon_3.svg";
import FeatureIcon_4 from "./assets/images/featureIcon_4.svg";

/*Addvalue Icons -------------------------------*/
import addValueIcon_1 from "./assets/images/addValueIcon_1.svg";
import addValueIcon_2 from "./assets/images/addValueIcon_2.svg";
import addValueIcon_3 from "./assets/images/addValueIcon_3.svg";
import addValueIcon_4 from "./assets/images/addValueIcon_4.svg";
import addValueIcon_5 from "./assets/images/addValueIcon_5.svg";
import addValueIcon_6 from "./assets/images/addValueIcon_6.svg";

/*securtyPrinting Icons -------------------------------*/
import securtyPrintingIcon_1 from "./assets/images/securtyPrintingIcon_1.svg";
import securtyPrintingIcon_2 from "./assets/images/securtyPrintingIcon_2.svg";
import securtyPrintingIcon_3 from "./assets/images/securtyPrintingIcon_3.svg";
import securtyPrintingIcon_4 from "./assets/images/securtyPrintingIcon_4.svg";
import securtyPrintingIcon_5 from "./assets/images/securtyPrintingIcon_5.svg";

/*Programing Icons -------------------------------*/
import programingIcon_1 from "./assets/images/programingIcon_1.svg";
import programingIcon_2 from "./assets/images/programingIcon_2.svg";
import programingIcon_3 from "./assets/images/programingIcon_3.svg";
import programingIcon_4 from "./assets/images/programingIcon_4.svg";
import programingIcon_5 from "./assets/images/programingIcon_5.svg";
import programingIcon_6 from "./assets/images/programingIcon_6.svg";

/*NetWorking Icons -------------------------------*/
import networkingIcon_1 from "./assets/images/networkingIcon_1.svg";
import networkingIcon_2 from "./assets/images/networkingIcon_2.svg";
import networkingIcon_3 from "./assets/images/networkingIcon_3.svg";
import networkingIcon_4 from "./assets/images/networkingIcon_4.svg";
import networkingIcon_5 from "./assets/images/networkingIcon_5.svg";
import networkingIcon_6 from "./assets/images/networkingIcon_6.svg";

/*clients logos -------------------------------*/
import client_logo_1 from "./assets/images/client_logo_1.png";
import client_logo_2 from "./assets/images/client_logo_2.png";
import client_logo_3 from "./assets/images/client_logo_3.png";
import client_logo_4 from "./assets/images/client_logo_4.png";

/*contacts Items*/
import call_ico_fax from "./assets/images/call_ico_fax.svg";
import call_ico_box from "./assets/images/call_ico_box.svg";
import call_ico_phone from "./assets/images/call_ico_phone.svg";
import call_ico_whats from "./assets/images/call_ico_whats.svg";
import call_ico_email from "./assets/images/call_ico_email.svg";

/*social media icons*/
import social_linked from "./assets/images/social_linked.svg";
import social_facebook from "./assets/images/social_facebook.svg";
// import social_twitter from "./assets/images/social_twitter.svg";
// import social_instgram from "./assets/images/social_instgram.svg";

/* Inside Page Description -------------------------------*/
import InsideAddValue from "./assets/images/InsideAddValue.png";
import InsidesecurityPrinting from "./assets/images/InsidesecurityPrinting.png";
import InsidePrograming from "./assets/images/InsidePrograming.png";
import InsideNetworking from "./assets/images/InsideNetworking.png";
import InsideClients from "./assets/images/InsideClients.png";
import InsideContatctUs from "./assets/images/InsideContatctUs.png";
import InsideAbout from "./assets/images/InsideAbout.png";

/*footerLogo*/
import FooterLogo from "./assets/images/footerLogo.png";

/*PDF icon*/
import PDFICON from "./assets/images/PDFICON.svg";


export const Text = {
  Logo: {
    logoName: "المسافة",
    LogoSlogin: "لتطوير تقنية الملعومات",
  },

  navLinks: {
    home: "الرئيسية",
    service: "خدماتنا",
    ourClients: "عملائنـا",
    aboutUs: "حول الشركة",
    contactUs: "تواصل معنا",
    lang: "English",
  },

  subMenu: {
    subTitle: "المزيد حول",
    addValue: "خدمات القيمة المضافة",
    securityPrintg: "الطباعة الأمنية",
    programing: "البرمجة وبناء التطبيقات",
    networking: "الشبكات وقواعد البيانات",
  },

  /*Main Slider Data -------------------------------*/
  MainSliderData: [
    {
      label: "خدمات (VAS)",
      cardName: "خدمات القيمة المضافة لأصحاب الشركات",
      cardBody:
        "نقدم مجموعة متكاملة من خدمات VAS لشركائنا لتحقيق اقصى ربح في اقصر مده",
      cardButton: "التفاصيل",
      cardImage: cardImage_1,
      cardColor: "#9DD7EF",
      path: "/addValue",
    },
    {
      label: "المستندات",
      cardName: "خدمات الطباعة الأمنية وحماية المستندات",
      cardBody:
        "تحسين مستندات الأمان الخاصة بك أو تكييف تصميماتك الحالية بدرجة أعلى من الحماية",
      cardButton: "التفاصيل",
      cardImage: cardImage_2,
      cardColor: "#FCE991",
      path: "/securityPrinting",
    },
    {
      label: "البرمجة",
      cardName: "خدمات البرمجة وبناء التطبيقات ",
      cardBody: "قم ببناء تطبيقك الخــاص لإدارة أعمالك بشكل أكثر سلاسة وكفاءة",
      cardButton: "التفاصيل",
      cardImage: cardImage_3,
      cardColor: "#B2DFDB",
      path: "/programing",
    },
    {
      label: "الشبكات",
      cardName: "بناء الشبكات الرقمية وقواعد البيانات",
      cardBody:
        "تساعد خبرة المسافة في خدمات البنية التحتية لتكنولوجيا المعلومات",
      cardButton: "التفاصيل",
      cardImage: cardImage_4,
      cardColor: "#9DD7EF",
      path: "/networking",
    },
  ],

  /*Company Info*/
  CompanyInfo: {
    CompanyName: "شركة المسافة ",
    CompanySlogin: "شركة ليبية رائدة في مجال تطوير تقنية المعلومات",
    CompanyBody:
      "تعد المسافة الآن واحدة من أكثر الأسماء شهرة في صناعة تكنولوجيا المعلومات وتتمتع بسمعة طيبة في تطوير وتقديم ونشر حلول وخدمات القيمة المضافة  للعديد من مشغلي الاتصالات والمؤسسات ومقدمي المحتوى. كفاءتنا الفنية القوية ومنهجيتنا المحددة جيدًا وفريق متخصص من المهندسين ",
  },

  /*Features*/
  Features: [
    {
      Featurename: "خبرة 10 سنوات",
      FeatureBody:
        "انطلاقنا في عام 2010 لنكون احد افضل مذودى خدمات القيمة المضافة في السوق الليبى",
      FeatureImage: FeatureIcon_1,
    },
    {
      Featurename: "دعم فنى متكامل",
      FeatureBody:
        "نقدم لعملائنا الكرام خدمات الدعم الفني المتكامل على مدار الاسوبع",
      FeatureImage: FeatureIcon_2,
    },
    {
      Featurename: "باقات متنوعة وأسعار تنافسية",
      FeatureBody:
        "لدينا مجموعة من الخدمات والباقات المتنوعة  بإسعار تناسب جميع العملاء",
      FeatureImage: FeatureIcon_3,
    },
    {
      Featurename: "فريق متخصص من المهندسين ",
      FeatureBody:
        "نخبة من المهندسين والفنين المتخصصون في تطوير الخدمات التقنية",
      FeatureImage: FeatureIcon_4,
    },
  ],

  /*GlobalButtons*/
  GlobalButtons: {
    ReadMore: "إقراء المزيد",
    Download: "حمل ملف الشركة",
    ViewAll: "عرض الكل",
  },
  /*GlobalTitels*/
  GlobalTitles: {
    HowUSTitle: "من نحن .؟",
    FeatureTitle: "مميزاتنا",
    OurClientsTitle: "عملائنا الكرام",
    FooterSectionsTitle: "أقسام الموقع",
    FooterServicesTitle: "خدمات الشركة",
    FooterDirectCallTitle: "الإتصال المباشر",
    FooterCompanyInfoTitle: "حول الشركة",
  },

  /*AddValue Service  -------------------------------*/
  AddValueService: [
    {
      serviceLable: "Short Code SMS",
      serviceTitle: "رسائل الكود المختصر",
      serviceBody:
        " تقدم المسافة حلولًا للرموز القصيرة وخدمات على بروتوكول الرسائل القصيرة التي يمكن أن تكون مخصصة أو شبه مخصصة ",
      serviceIcon: addValueIcon_1,
    },
    {
      serviceLable: "Bulk SMS ",
      serviceTitle: "الرسائل الجماعية",
      serviceBody:
        " الرسائل القصيرة الجماعية تتيح هذه الخدمة فرصة إرسال الرسالة لعدد كبير من العملاء دفعة واحدة ",
      serviceIcon: addValueIcon_2,
    },
    {
      serviceLable: "Interactive SMS ",
      serviceTitle: "الرسائل التفاعلية",
      serviceBody:
        "تُستخدم هذه الخدمة لجمع الملاحظات وردود الفعل والاقتراحات الأخرى من المشتركين والعملاء ",
      serviceIcon: addValueIcon_3,
    },
    {
      serviceLable: "Pull SMS ",
      serviceTitle: "رسائل دعائية ترويجية",
      serviceBody:
        "خدمة  توفر المعلومات للعميل دون تقديم أي تفاعل. هذا مفيد بشكل خاص في حالات التسويق والترويج للمنتجات",
      serviceIcon: addValueIcon_4,
    },
    {
      serviceLable: "Push SMS",
      serviceTitle: "رسائل الإستعلام والتوثيق",
      serviceBody:
        "تقترح هذه الخدمة على المشتركين طلب الخدمة عبر إرسال رمز قصير كرسالة نصية قصيرة إلى مشغل الاتصالات لطلب الخدمة",
      serviceIcon: addValueIcon_5,
    },
    {
      serviceLable: "Packages , Prices ",
      serviceTitle: "الباقات والأسعار",
      serviceBody: "لباقات الرسائل والاسعار قم بزيارة موقع الرسائل",
      serviceIcon: addValueIcon_6,
      serviceURL: "http://rasael.ly/",
      serviceURLName: "زيارة الموقع",
    },
  ],

  /*SecurtyPrinting Service  -------------------------------*/
  SecurtyPrintingService: [
    {
      serviceLable: "Cheque Books",
      serviceTitle: "دفاتر الشيكات",
      serviceBody:
        "تعد المسافة من افضل الشركات الليبية فى الطباعه الامنية وطباعه دفاتر الشيكات",
      serviceIcon: securtyPrintingIcon_1,
    },

    {
      serviceLable: "watermarks",
      serviceTitle: "العلامات المائية للمستندات",
      serviceBody:
        " العلامات المائية هى عبارة عن رمز شفاف يتم طباعه على المستندات لحمايتها من التزوير",
      serviceIcon: securtyPrintingIcon_2,
    },

    {
      serviceLable: "Financial Documents",
      serviceTitle: "المستندات المالية",
      serviceBody:
        "إجعل من الصعب تزوير علامتك التجارية على الوثائق والمستندات الخاصة بك",
      serviceIcon: securtyPrintingIcon_3,
    },

    {
      serviceLable: "commercial Posters ",
      serviceTitle: "الملصقات التجارية",
      serviceBody:
        "هى ملصقات مائية خاصة بعلامتك التجارية لحمايتك منتجاتك من التقليد",
      serviceIcon: securtyPrintingIcon_4,
    },

    {
      serviceLable: "contracts",
      serviceTitle: "الوثائق والعقود ",
      serviceBody:
        " قم بتحسين مستندات الأمان الخاصة بك أو تعديل تصميماتك الحالية بدرجة أعلى من الحماية اجعل من الصعب قدر الإمكان تزوير علامتك التجارية",
      serviceIcon: securtyPrintingIcon_5,
    },
  ],

  /*Programing Service  -------------------------------*/
  ProgramingService: [
    {
      serviceLable: "Android Apps",
      serviceTitle: "تطبيقات الأندرويد",
      serviceBody:
        "نقوم بتصميم وبرمجة تطبيقات الهواتف الذكية تعمل على كافة إصدارات نظام الإندرويد ",
      serviceIcon: programingIcon_1,
    },
    {
      serviceLable: "IOS Apps",
      serviceTitle: "تطبيقات الايفون",
      serviceBody:
        "نقوم بتصميم وبرمجة تطبيقات الهواتف الذكية تعمل على كافة إصدارات نظام الإيفون",
      serviceIcon: programingIcon_2,
    },
    {
      serviceLable: "Web Applications",
      serviceTitle: "تطبيقات ومواقع الإنترنت",
      serviceBody:
        "متخصصون فى تصميم وبرمجة مواقع وتطبيقات الإنترنت المتجاوبة مع كافة الاجهزة المحمولة",
      serviceIcon: programingIcon_3,
    },
    {
      serviceLable: "Corporate Systems",
      serviceTitle: "انظمة الشركات والمؤسسات",
      serviceBody:
        "قامت شركة المسافة طوال سنوات عديده باعداد انظمه مكتمله معده للشركات والمدارس",
      serviceIcon: programingIcon_4,
    },
    {
      serviceLable: "Management Systems",
      serviceTitle: "أنظمة محاسبية وإدارية",
      serviceBody:
        "توفر المسافة عدد من الأنظمة المحاسبية والإدارية للشركات والمؤسسات",
      serviceIcon: programingIcon_5,
    },
    {
      serviceLable: "e commerce",
      serviceTitle: "تطبيقات المشتريات والتسوق",
      serviceBody:
        "تطبيقات ومواقع إلكترونية تسمح للمستهلكين لشراء السلع أو الخدمات مباشرة من البائع عبر الإنترنت",
      serviceIcon: programingIcon_6,
    },
  ],

  /*Networking Service  -------------------------------*/
  NetworkingService: [
    {
      serviceLable: "Networks",
      serviceTitle: "الشبكات الرقمية",
      serviceBody:
        "تأسيس البنية التحتية والشبكات الرقمية اللتى تتيح سهولة نقل البيانات والمعلومات",
      serviceIcon: networkingIcon_1,
    },
    {
      serviceLable: "Databases",
      serviceTitle: "قواعد البيانات",
      serviceBody:
        "متخصصون فى بناء وهيكلة قواعد البيانات المحمية لتخزين كافة البيانات",
      serviceIcon: networkingIcon_2,
    },
    {
      serviceLable: "Protection System",
      serviceTitle: "نظام الحماية",
      serviceBody:
        "تعد المسافة من أولى الشركات المتخصصة فى الحمايه وامن المعلومات ",
      serviceIcon: networkingIcon_3,
    },
    {
      serviceLable: "Server And Storage ",
      serviceTitle: "الخادم والتخزين",
      serviceBody:
        "لدينا افضل الاجهزة تتحمل ظروف التشغيل بإستمرار لفترات طويلة قد تصل لأكثر من عام ",
      serviceIcon: networkingIcon_4,
    },
    {
      serviceLable: "Data Centers",
      serviceTitle: "مراكز البيانات",
      serviceBody:
        "متخصصون فى تجهيز مبنى خاص لضم كافة الخوادم طبقا لبيئة مناسبة من درجات الحرارة  ",
      serviceIcon: networkingIcon_5,
    },
    {
      serviceLable: "Platform Management ",
      serviceTitle: "إدارة المنصات",
      serviceBody:
        "  لدينا فريق متخصص فى إدارة المنصات الإلكترونية وإدخال البيانات والمعلومات   ",
      serviceIcon: networkingIcon_6,
    },
  ],

  /*clientsLogos images  -------------------------------*/

  clientsLogos: [
    {
      clientLogoImage: client_logo_1,
      clientLogoName: "المصرف المتحد للتجارة  والإستثمار",
      clientLogobody: "United Bank",
    },
    {
      clientLogoImage: client_logo_2,
      clientLogoName: "مصرف الأندلس",
      clientLogobody: "Andalus Bank",
    },
    {
      clientLogoImage: client_logo_3,
      clientLogoName: "مصرف الواحة",
      clientLogobody: "Alwaha Bank",
    },
    {
      clientLogoImage: client_logo_4,
      clientLogoName: "مصرف المتوسط",
      clientLogobody: "Mediterranean bank",
    },
  ],

  /*Contatcs items  -------------------------------*/

  contactsItems: {
    Location: {
      name: 'طريق قرقريش ، طرابلس ليبيا'
    },
    FaxItem: {
      icon: call_ico_fax,
      lable: "فاكس /",
      data: "+218 21 481 0394",
    },

    BoxItem: {
      icon: call_ico_box,
      lable: "صندوق بريد /",
      data: "91108",
    },

    PhoneItem: {
      icon: call_ico_phone,
      data: "091 401 1188",
      url: "tel:0914011188",
    },

    WhatsItem: {
      //icon: call_ico_whats,
      icon: call_ico_phone,
      data: "092 401 1188",
      url: "tel:0914011188",
      //url: "https://wa.me/+218925000083",
    },

    EmailItem: {
      icon: call_ico_email,
      data: "info@masafa.ly",
      url: "mailto:info@masafa.ly",
    },
  },

  /*socialMedia Icons   -------------------------------*/
  socialMedia: [
    {
      icon: social_facebook,
      url: 'https://web.facebook.com/Masafa.ly/?_rdc=1&_rdr'
    },
    {
      icon: social_linked,
      url: 'https://www.linkedin.com/company/masafa-for-it-development'
    },
  ],

  /*Copy Rights   -------------------------------*/
  CoyRights: {
    logo: FooterLogo,
    head: 'جميع الحقوق محفوظة لشركة المسافة   |   حقوق النشر 2021',
    body: 'سنة التأسيس 2010'
  },

  /* Inside Page Titles -------------------------------*/
  InsidePageTitles: {
    OurClientsPage: {
      TitleName: 'عملائنا الكرام شركاء في مسيرة النجاح',
    },
    AboutUsPage: {
      TitleName: 'شركة المسافة لتطوير تقنية المعلومات',
    },
    ContactUsPage: {
      TitleName: 'تواصل معنا الأن عبر وسائل الإتصال التالية',
    },
  },
  /* Inside Page Description -------------------------------*/

  InsidePageDescription: {
    AddValuePage: {
      descriptionHead: 'تعد المسافة  أحد أفضل مزودي حلول VAS في السوق الليبي',
      descriptionBody: 'بصفتنا شركة خدمات ذات قيمة مضافة ، فإننا نقدم مجموعة شاملة من منتجات وخدمات القيمة المضافة التي تزود شركائنا بالأدوات والحلول التي يحتاجونها لتحقيق أقصى قدر ممكن من الأرباح مع الحد الأدنى من التأخير  وتتنوع خدماتنا كالأتى',
      descriptionImage: InsideAddValue,
    },
    SecurityPrintingPage: {
      descriptionHead: 'إجعل من الصعب تزوير علامتك التجارية على الوثائق والمستندات الخاصة بك',
      descriptionBody: 'قم بتحسين مستندات الأمان الخاصة بك أو تكييف تصميماتك الحالية بدرجة أعلى من الحماية اجعل من الصعب قدر الإمكان على مزور أو عامل رقمي تزوير علامتك التجارية ومنتجاتك ووثائقك من خلال دفع حدود الإبداع وتصميم الأمان',
      descriptionImage: InsidesecurityPrinting,
    },
    ProgramingPage: {
      descriptionHead: 'قم ببناء تطبيقك الخاص لإدارة أعمالك بشكل أكثر سلاسة وكفاءة',
      descriptionBody: 'تقوم المسافة بتطوير تطبيقات مبنية بمجموعة قوية من الميزات المصممة خصيصًا لتناسب علامتك التجارية. يمكن أن تساعدك هذه التطبيقات القابلة للتطوير والمرنة والقابلة للتشغيل البيني في إدارة أعمالك بشكل أكثر سلاسة وكفاءة',
      descriptionImage: InsidePrograming,
    },
    NetworkingPage: {
      descriptionHead: 'بنية تحتية متكاملة لتكنولوجيا المعلومات',
      descriptionBody: 'تساعد خبرة المسافة في خدمات البنية التحتية لتكنولوجيا المعلومات والشبكات ومراكز البيانات ونماذج تقديم الخدمات العملاء في دعم أنظمة الأعمال الهامة للنمو والتميز التشغيلي',
      descriptionImage: InsideNetworking,
    },
    OurClientsPage: {
      descriptionHead: 'عملائنا الكرام شركاء في مسيرة النجاح',
      descriptionBody: 'تعد المسافة الآن واحدة من أكثر الأسماء شهرة في صناعة تكنولوجيا المعلومات وتتمتع بسمعة طيبة في تطوير وتقديم ونشر حلول وخدمات القيمة المضافة  للعديد من مشغلي الاتصالات والمؤسسات ومقدمي المحتوى. كفاءتنا الفنية القوية ومنهجيتنا المحددة جيدًا وفريق متخصص من المهندسين ',
      descriptionImage: InsideClients,
    },
    AboutUsPage: {
      descriptionHead: 'شركة ليبية رائدة في مجال تطوير تقنية المعلومات',
      descriptionBody: ' تعد المسافة الآن واحدة من أكثر الأسماء شهرة في صناعة تكنولوجيا المعلومات وتتمتع بسمعة طيبة في تطوير وتقديم ونشر حلول وخدمات القيمة المضافة  للعديد من مشغلي الاتصالات والمؤسسات ومقدمي المحتوى. كفاءتنا الفنية القوية ومنهجيتنا المحددة جيدًا وفريق متخصص من المهندسين  ',
      descriptionImage: InsideAbout,
    },
    ContactUsPage: {
      descriptionHead: 'نريد أن نسمع منك سواء كان لديك إستفسار أو ملاحظات',
      descriptionBody: 'هناك عدد من الطرق التي يمكنك من خلالها التواصل معنا. نحن نبذل قصارى جهدنا لتقديم المساعدة. لا تتردد في الاتصال بنا إذا لم تتمكن من العثور على إجابة لسؤالك.',
      descriptionImage: InsideContatctUs,
    },
  },

  /*/*/

  VisionAndMission: [
    {
      title: 'التأسيس',
      body: ' تم تأسيس الشركة في عام 2010 لنقدم خدمات القيمة المضافة في السوق الليبى'
    },
    {
      title: 'روئيتنا',
      body: ' تتمثل رؤيتنا في الحفاظ على علاقة طويلة الأمد مع عملائنا بناءً على الدقة والكفاءة'
    },
    {
      title: 'أهدافنا',
      body: ' أن نكون لاعبًا رائدًا في مجال الخدمات ذات القيمة المضافة وصناعة تطوير تطبيقات الهاتف المحمول'
    },
  ],


  /*PDFBOX Download  -------------------------------*/
  PDFBOX: {
    lable: "Company Profile",
    Title: "تحميل ملف الشركة",
    Body: "إضغط لتحميل ملف الشركة والإطلاع على كافة الخدمات ووسائل الإتصال",
    Icon: PDFICON,

  },

  /*Contat form label Download  -------------------------------*/
  ContactFormLabel: {
    name: 'الإسم',
    email: 'البريد الإلكترونى',
    subject: 'عنوان الرسالة',
    message: 'التفاصيل',
    button: 'إرسال',

  },


};
