import { Typography } from "@mui/material";
import React from 'react'

const BoxtitleStyle = {
    position: 'relative',
    height: '42px',
    mb: '36px',
    display: 'inline-block',
    
    '&::after': {
        content: "''",
        width: '28px',
        height: '1px',
        background: '#000',
        position: 'absolute',
        bottom: 0,
        left: 0,
        display:'block',
        
    }
}


function BoxTitle(props) {

    return (
        <div>
            <Typography variant="h1" sx={BoxtitleStyle}>{props.children}</Typography>
        </div>
    )
}

export default BoxTitle

