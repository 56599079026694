import {
  Button,
  Collapse,
  Container,
  Divider,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import React, { Fragment, useState } from "react";
import {
  NavBarContainer,
  NavBarGlobalStyle,
  Logo,
  LogoIcon,
} from "./NavBarStyle";
import { Text } from "../../Text";
import { NavLink as RouterLink } from "react-router-dom";

import ButtonBase from "@mui/material/ButtonBase";
import { DropMenuStyle } from "./NavBarStyle";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MenuIcon from "@mui/icons-material/Menu";

import LanguageIcon from "@mui/icons-material/Language";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import Icon from "react-eva-icons";
import { Direction } from "../../Theme/MainDirection";

export default function NavBar() {
  // Fixed NavBar useing Hooks ---------------------
  const [NavPosation, setNavPosation] = useState(false);
  const fixedNav = () => {
    window.scrollY >= 150 ? setNavPosation(true) : setNavPosation(false);
  };
  window.addEventListener("scroll", fixedNav);

  //Nav Sub Menu -----------------------------------------
  const [subMenu, setsubMenu] = useState(false);

  // drower Menu open  ----------------------------------
  const [openMenu, setopenMenu] = useState(false);
  //drower Menu Collaps subMenu
  const [drowerSubMenu, setdrowerSubMenu] = useState(false);
  //drower item Selected
  const [selectedItem, setselectedItem] = useState(0);

  return (
    <Fragment>
      <NavBarContainer className={NavPosation ? "fixedNav" : "normalNav"}>
        <Container fixed>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item className="menuButton">
              <Button
                disableElevation={true}
                sx={{ minWidth: 2, padding: 1, marginRight: 2 }}
                variant="contained"
                color="secondary"
                onClick={() => setopenMenu(true)}
              >
                <MenuIcon />
              </Button>
            </Grid>
            <Grid item>
              <Logo>
                <ButtonBase component={RouterLink} to="/">
                  <LogoIcon />
                  <Typography component="article">
                    <Typography variant="h1">{Text.Logo.logoName}</Typography>
                    <Typography variant="body1" className="hideOnScroll">
                      {Text.Logo.LogoSlogin}
                    </Typography>
                  </Typography>
                </ButtonBase>
              </Logo>
            </Grid>
            <Grid item className="navLinksContainer">
              <Button color="inherit" component={RouterLink} exact to="/">
                {Text.navLinks.home}
              </Button>
              <Button
                color="inherit"
                onClick={(e) => {
                  setsubMenu(e.currentTarget);
                }}
                endIcon={<ArrowDropDownIcon />}
              >
                {Text.navLinks.service}
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={subMenu}
                open={subMenu}
                onClose={() => setsubMenu(false)}
                PaperProps={{
                  elevation: 0,
                  sx: { ...DropMenuStyle },
                }}
                transformOrigin={{
                  horizontal: Direction.Right,
                  vertical: "top",
                }} // animation point
                anchorOrigin={{
                  horizontal: Direction.Right,
                  vertical: "bottom",
                }} // show point
              >
                <Button
                  className="subMenuItem"
                  component={RouterLink}
                  to="/addValue"
                  color="inherit"
                  onClick={() => setsubMenu(false)}
                >
                  <span className="dropIco dropDownicon_1"></span>
                  <Typography component="article">
                    <Typography variant="body2">
                      {Text.subMenu.subTitle}
                    </Typography>
                    <Typography variant="h6">
                      {Text.subMenu.addValue}
                    </Typography>
                  </Typography>
                </Button>

                <Button
                  className="subMenuItem"
                  component={RouterLink}
                  to="/securityPrinting"
                  color="inherit"
                  onClick={() => setsubMenu(false)}
                >
                  <span className="dropIco dropDownicon_2"></span>
                  <Typography component="article">
                    <Typography variant="body2">
                      {Text.subMenu.subTitle}
                    </Typography>
                    <Typography variant="h6">
                      {Text.subMenu.securityPrintg}
                    </Typography>
                  </Typography>
                </Button>

                <Button
                  className="subMenuItem"
                  component={RouterLink}
                  to="/programing"
                  color="inherit"
                  onClick={() => setsubMenu(false)}
                >
                  <span className="dropIco dropDownicon_3"></span>
                  <Typography component="article">
                    <Typography variant="body2">
                      {Text.subMenu.subTitle}
                    </Typography>
                    <Typography variant="h6">
                      {Text.subMenu.programing}
                    </Typography>
                  </Typography>
                </Button>

                <Button
                  className="subMenuItem"
                  component={RouterLink}
                  to="/networking"
                  color="inherit"
                  onClick={() => setsubMenu(false)}
                >
                  <span className="dropIco dropDownicon_4"></span>
                  <Typography component="article">
                    <Typography variant="body2">
                      {Text.subMenu.subTitle}
                    </Typography>
                    <Typography variant="h6">
                      {Text.subMenu.networking}
                    </Typography>
                  </Typography>
                </Button>
              </Menu>

              <Button component={RouterLink} to="/ourClients" color="inherit">
                {Text.navLinks.ourClients}
              </Button>
              <Button component={RouterLink} to="/aboutUs" color="inherit">
                {Text.navLinks.aboutUs}
              </Button>
              <Button component={RouterLink} to="/contactUs" color="inherit">
                {Text.navLinks.contactUs}
              </Button>
            </Grid>
            <Grid item>
              <Button
                component="a" href="https://www.masafa.ly/en"
                sx={{ fontWeight: 400, textTransform: "capitalize" }}
                color="inherit"
                endIcon={<LanguageIcon sx={{ color: "#4497D9" }} />}
              >
                {Text.navLinks.lang}
              </Button>
            </Grid>
          </Grid>
        </Container>

        {/* Side Menu ----------------------------------------------------- */}
        <SwipeableDrawer
          anchor="left"
          open={openMenu}
          onClose={() => setopenMenu(false)}
        >
          <List className="sideMenu">
            <Logo style={{ padding: "30px 0px" }}>
              <LogoIcon />
              <Typography component="article">
                <Typography variant="h1">{Text.Logo.logoName}</Typography>
                <Typography variant="body1" className="hideOnScroll">
                  {Text.Logo.LogoSlogin}
                </Typography>
              </Typography>
            </Logo>

            <ListItemButton
              exact
              to="/"
              component={RouterLink}
              selected={selectedItem === 0}
              onClick={() => {
                setselectedItem(0);
                setopenMenu(false);
              }}
            >
              <ListItemIcon>
                <Icon name="home" fill="rgba(0,0,0,0.4)" size="meduim" />
              </ListItemIcon>
              <ListItemText primary={Text.navLinks.home} />
            </ListItemButton>
            <Divider variant="inset" component="li" />

            <ListItemButton onClick={() => setdrowerSubMenu(!drowerSubMenu)}>
              <ListItemIcon>
                <Icon name="pricetags" fill="rgba(0,0,0,0.4)" size="meduim" />
              </ListItemIcon>
              <ListItemText primary={Text.navLinks.service} />
              {drowerSubMenu ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>

            <Collapse in={drowerSubMenu} sx={{ bgcolor: "#FCE991" }}>
              <List disablePadding>
                <ListItemButton
                  to="/addValue"
                  component={RouterLink}
                  sx={{ pl: 4 }}
                  selected={selectedItem === 1}
                  onClick={() => {
                    setselectedItem(1);
                    setopenMenu(false);
                  }}
                >
                  <ListItemText primary={Text.subMenu.addValue} />
                </ListItemButton>
                <Divider component="li" />

                <ListItemButton
                  to="/securityPrinting"
                  component={RouterLink}
                  sx={{ pl: 4 }}
                  selected={selectedItem === 2}
                  onClick={() => {
                    setselectedItem(2);
                    setopenMenu(false);
                  }}
                >
                  <ListItemText primary={Text.subMenu.securityPrintg} />
                </ListItemButton>
                <Divider component="li" />

                <ListItemButton
                  to="/programing"
                  component={RouterLink}
                  sx={{ pl: 4 }}
                  selected={selectedItem === 3}
                  onClick={() => {
                    setselectedItem(3);
                    setopenMenu(false);
                  }}
                >
                  <ListItemText primary={Text.subMenu.programing} />
                </ListItemButton>
                <Divider component="li" />

                <ListItemButton
                  to="/networking"
                  component={RouterLink}
                  sx={{ pl: 4 }}
                  selected={selectedItem === 4}
                  onClick={() => {
                    setselectedItem(4);
                    setopenMenu(false);
                  }}
                >
                  <ListItemText primary={Text.subMenu.networking} />
                </ListItemButton>
                <Divider component="li" />
              </List>
            </Collapse>
            <Divider variant="inset" component="li" />

            <ListItemButton
              to="/ourClients"
              component={RouterLink}
              selected={selectedItem === 5}
              onClick={() => {
                setselectedItem(5);
                setopenMenu(false);
              }}
            >
              <ListItemIcon>
                <Icon name="people" fill="rgba(0,0,0,0.4)" size="meduim" />
              </ListItemIcon>
              <ListItemText primary={Text.navLinks.ourClients} />
            </ListItemButton>
            <Divider variant="inset" component="li" />

            <ListItemButton
              to="/aboutUs"
              component={RouterLink}
              selected={selectedItem === 6}
              onClick={() => {
                setselectedItem(6);
                setopenMenu(false);
              }}
            >
              <ListItemIcon>
                <Icon
                  name="alert-circle"
                  fill="rgba(0,0,0,0.4)"
                  size="meduim"
                />
              </ListItemIcon>
              <ListItemText primary={Text.navLinks.aboutUs} />
            </ListItemButton>
            <Divider variant="inset" component="li" />

            <ListItemButton
              to="/contactUs"
              component={RouterLink}
              selected={selectedItem === 7}
              onClick={() => {
                setselectedItem(7);
                setopenMenu(false);
              }}
            >
              <ListItemIcon>
                <Icon name="email" fill="rgba(0,0,0,0.4)" size="meduim" />
              </ListItemIcon>
              <ListItemText primary={Text.navLinks.contactUs} />
            </ListItemButton>
          </List>
        </SwipeableDrawer>
      </NavBarContainer>

      {/* Nav Global Style */}
      <NavBarGlobalStyle />
    </Fragment>
  );
}
