import { Grid } from '@mui/material';
import React from 'react';
import InsideMainContent from '../../Components/InsideMainContent/InsideMainContent';
import InsidePageDescription from "../../Components/InsidePageDescription/InsidePageDescription";
import InsidePageTitle from "../../Components/InsidePageTitle/InsidePageTitle";
import ClientsLogosData from '../../Components/OurClients/OurClientsData';
import { Text } from "../../Text";
function OurClients() {
    return (
        <div>
            <InsidePageTitle pageName={Text.navLinks.ourClients}>{Text.InsidePageTitles.OurClientsPage.TitleName}</InsidePageTitle>
            <InsidePageDescription
                descriptionImage={
                    Text.InsidePageDescription.OurClientsPage.descriptionImage
                }
                descriptionHead={
                    Text.InsidePageDescription.OurClientsPage.descriptionHead
                }
                descriptionBody={
                    Text.InsidePageDescription.OurClientsPage.descriptionBody
                }
            />
            <InsideMainContent>
                <Grid container  sx={{ border: "1px solid #E6EBF1", borderRight: "0px" }}>
                    {ClientsLogosData}
                </Grid>
            </InsideMainContent>
        </div>
    )
}

export default OurClients
