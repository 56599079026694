import React from "react";
import ReactOwlCarousel from "react-owl-carousel-rtl";
import { SecurtyPrintingSliderData } from "./SecurtyPrintingData";
import { ServiceCategoriesConatiner } from "../ServiceCategories/ServiceCategoriesStyle";
import { ServiceCategoriesOptions } from "../ServiceCategories/ServiceCategoriesSliderOptions";

function SecurtyPrintingSlider() {
  return (
    <ServiceCategoriesConatiner>
      <ReactOwlCarousel className="owl-theme" {...ServiceCategoriesOptions}>
        {SecurtyPrintingSliderData}
      </ReactOwlCarousel>
    </ServiceCategoriesConatiner>
  );
}

export default SecurtyPrintingSlider;
