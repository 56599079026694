import customTheme from "./theme"

const size = {
    xs: customTheme.breakpoints.values.xs,
    sm: customTheme.breakpoints.values.sm,
    md: customTheme.breakpoints.values.md,
    lg: customTheme.breakpoints.values.lg,
    xl: customTheme.breakpoints.values.xl
}
const device = {
    xs: `(min-width: ${size.xs}px)`,
    sm: `(min-width: ${size.sm}px)`,
    md: `(min-width: ${size.md}px)`,
    lg: `(min-width: ${size.lg}px)`,
    xl: `(min-width: ${size.xl}px)`,
}
export default { size, device }