import styled from "styled-components";
export const ServiceBoxItem = styled.div`
  background: #fff;
  height: 308px;
  border-radius: 10px;
  padding: 0px 30px;
  text-align: center;
  display: block;
  article {
    display: block;
    h3 {
      margin: 6px 0px 12px 0px;
    }
    p {
      color: #555;
      &:first-of-type{
          color:#000;
          font-weight: 500;
      }
    }
    a {
      color: #0076e2;
      text-decoration: underline;
      font-weight: 500;
      font-size: 0.875rem;
      margin-top: 18px;
      display: inline-block;
      transition: all 0.2s ease;
      &:hover {
        //text-decoration: none;
        color: orange;
      }
    }
  }

  img {
    width: 77px !important;
    height: 73px !important;
    display: inline-block !important;
    margin: 35px 0px 24px 0px;
  }
`;
