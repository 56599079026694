
export const Direction = {

    Dir: 'rtl',
    //----------------------------
    Right: 'right',
    PaddingRight: 'padding-right',
    MarginRight: 'margin-right',
    BorderRight: 'border-right',
    //----------------------------
    Left: 'left',
    PaddingLeft: 'padding-left',
    MarginLeft: 'margin-left',
    BorderLeft: 'border-left',
    //----------------------------
    owlRTL: true

}

// FOR English //////////////////////////////////////////

// Dir: 'ltr',
// //----------------------------
// Right: 'left',
// PaddingRight: 'padding-left',
// MarginRight: 'margin-left',
// //----------------------------
// Left: 'right',
// PaddingLeft: 'padding-right',
// MarginLeft: 'margin-right',
// //----------------------------
// owlRTL: false
