import { Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { Direction } from "../../Theme/MainDirection";

const ContactItemStyle = styled.div`
  a {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 6px 0px;
    color:#000000;
    text-decoration: none;
    &:hover{
        text-decoration: underline;
    }
    img {
      ${Direction.MarginLeft}:12px;
    }
    i {
      font-style: normal;
      font-weight: 400;
      font-size: 0.75rem;
    }
    p {
      font-weight: 600;
      font-size: 0.75rem;
    }
  }
`;
function ContactsItem(props) {
    return (
        <ContactItemStyle>
            <a href={props.url} target="_blank" rel="noreferrer">
                <img src={props.icon} alt="icon" />
                <Typography component="i">{props.lable}</Typography>
                <Typography dir="ltr" variant="body1">
                    {props.contactData}
                </Typography>
            </a>
        </ContactItemStyle>
    );
}

export default ContactsItem;
