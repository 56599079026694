import { Container, Typography } from "@mui/material";
import { NavLink as RouterLink } from "react-router-dom";
import React from "react";
import styled from "styled-components";
import customTheme from "../../Theme/theme";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { Text } from "../../Text";
import Brackpoints from "../../Theme/Brackpoints";
import { motion } from "framer-motion";

const InsidePageTitleContainer = styled.div`
  background-color: ${customTheme.palette.primary.main};
  min-height: 187px;
  border-radius: 8px;
  padding: 27px 43px;
  h1 {
    margin-top: 20px;
    @media ${Brackpoints.device.md} {
      margin-top: 23px;
    }
    width: 360px;
    max-width: 100%;
  }
`;
function InsidePageTitle(props) {
  return (
    <Container fixed>
      <motion.div
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{delay:0.1 , duration:0.4 , type:"spring" }}
      
      
      >
        <InsidePageTitleContainer>
          <Breadcrumbs aria-label="breadcrumb">
            <Link component={RouterLink} to="/" color="inherit">
              {" "}
              {Text.navLinks.home}{" "}
            </Link>
            <Typography color="text.primary">{props.pageName}</Typography>
          </Breadcrumbs>
          <Typography variant="h1">{props.children}</Typography>
        </InsidePageTitleContainer>
      </motion.div>
    </Container>
  );
}

export default InsidePageTitle;
