import React from "react";
import { ButtonBase, Container, Grid, Typography } from "@mui/material";
import SmallContainer from "../../Theme/SmallContainer";
import styled from "styled-components";
import BoxTitle from "../BoxTitle/BoxTitle";
import { Text } from "../../Text";
import { FeatureItem } from "../CompanyInfo/CompanyInfoStyle";
import { ServiceBoxItem } from "../ServiceCategoryItemDesign/ServiceCategoryItemDesign";
import profileFile from "../../assets/images/AboutMasafa.docx";
import Brackpoints from "../../Theme/Brackpoints";

const FeaturesContainer = styled.section`
  padding: 30px 0px 20px 0px;
  @media ${Brackpoints.device.md}{
    padding: 90px 0px 60px 0px;
  }
`
function Features() {
  return (
    <FeaturesContainer>
      <SmallContainer>
        <Container fixed>

          <Grid container  >

            <Grid item xs={12} md={8}>
              <BoxTitle>{Text.GlobalTitles.FeatureTitle}</BoxTitle>
              <Grid container rowSpacing={{ xs: 2, md: 6 }} columnSpacing={2}>

                {Text.Features.map((item, index) => {
                  return (
                    <Grid item xs={12} md={6} key={index}>
                      <FeatureItem>
                        <img src={item.FeatureImage} alt={item.Featurename} />
                        <Typography component="article">
                          <Typography variant="h4">
                            {item.Featurename}
                          </Typography>
                          <Typography variant="body1">
                            {item.FeatureBody}
                          </Typography>
                        </Typography>
                      </FeatureItem>
                    </Grid>
                  )
                })}

              </Grid>
            </Grid>

            <Grid item xs={12} md={4} pl={{ md: '40px' }} pt={{xs:'40px', md: '0px' }}>

              <ButtonBase component="a" href={profileFile} sx={{ display: "block" }}>
                <ServiceBoxItem style={{ background: '#9DD7EF' }}>
                  <img src={Text.PDFBOX.Icon} alt={Text.PDFBOX.Title} />
                  <Typography component="article">
                    <Typography variant="body1">{Text.PDFBOX.lable}</Typography>
                    <Typography variant="h3">{Text.PDFBOX.Title}</Typography>
                    <Typography variant="body1">{Text.PDFBOX.Body} </Typography>
                  </Typography>
                </ServiceBoxItem>
              </ButtonBase>

            </Grid>

          </Grid>

        </Container>

      </SmallContainer>
    </FeaturesContainer>
  );
}

export default Features;
