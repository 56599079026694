import { Button, Container, Divider, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import BoxTitle from "../BoxTitle/BoxTitle";
import { CompanyInfoContainer, FeatureItem, FeatureSliderContainer, InfoBox } from "./CompanyInfoStyle";
import infoIcon from "../../assets/images/Infoicon.png";
import { Text } from "../../Text";
import ReactOwlCarousel from "react-owl-carousel-rtl";
import { Direction } from "../../Theme/MainDirection";
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import DownloadIcon from '@mui/icons-material/Download';
import { NavLink as RouterLink } from "react-router-dom";
import profileFile from "../../assets/images/AboutMasafa.docx";




const FeatureSliderOptions = {
  items: 1,
  nav: false,
  navText: ['', ''],
  margin: 15,
  rtl: Direction.owlRTL,
  autoplay: false,
  smartSpeed: 450,
  autoplaySpeed: 450,


}
function CompanyInfo() {
  return (
    <CompanyInfoContainer>
      <Container fixed>
        <Grid container>
          <Grid item xs={12} lg={8}>
            <BoxTitle>{Text.GlobalTitles.HowUSTitle}</BoxTitle>
            <InfoBox>
              <img src={infoIcon} alt={Text.CompanyInfo.CompanyName} />
              <Typography component="article">
                <Typography variant="h2">
                  {Text.CompanyInfo.CompanyName}
                </Typography>
                <Typography variant="h6">
                  {Text.CompanyInfo.CompanySlogin}
                </Typography>
                <Typography variant="body1">
                  {Text.CompanyInfo.CompanyBody}
                </Typography>
                <Stack direction>
                  <Button

                    sx={{ fontWeight: 500, textDecoration: "underline", fontSize:"0.75rem" }}
                    component={RouterLink}
                    to="/aboutUs"
                    color="inherit"
                    startIcon={<ControlPointIcon sx={{ color: "#0076E2" }} />}
                  >
                    {Text.GlobalButtons.ReadMore}
                  </Button>
                  <Divider sx={{margin:"10px"}} component="li" orientation="vertical" variant="middle" flexItem />
                  <Button

                    sx={{ fontWeight: 500, textDecoration: "underline", fontSize: "0.75rem" }}
                    color="inherit"
                    component="a"
                    target="_blank"
                    href={profileFile}

                    startIcon={<DownloadIcon sx={{ color: "#0076E2" }} />}
                  >
                    {Text.GlobalButtons.Download}
                  </Button>
                </Stack>

              </Typography>
            </InfoBox>

          </Grid>


          <Grid item xs={12} lg={4}>
            <BoxTitle>{Text.GlobalTitles.FeatureTitle}</BoxTitle>

            <FeatureSliderContainer>
              <ReactOwlCarousel className="owl-theme" {...FeatureSliderOptions}>

                <Grid container rowSpacing={4}>
                  <Grid item xs={12} md={6} lg={12}>
                    <FeatureItem>
                      <img src={Text.Features[0].FeatureImage} alt={Text.Features[0].Featurename} />
                      <Typography component="article">
                        <Typography variant="h4">
                          {Text.Features[0].Featurename}
                        </Typography>
                        <Typography variant="body1">
                          {Text.Features[0].FeatureBody}
                        </Typography>
                      </Typography>
                    </FeatureItem>
                  </Grid>
                  <Grid item xs={12} md={6} lg={12}>
                    <FeatureItem>
                      <img src={Text.Features[1].FeatureImage} alt={Text.Features[1].Featurename} />
                      <Typography component="article">
                        <Typography variant="h4">
                          {Text.Features[1].Featurename}
                        </Typography>
                        <Typography variant="body1">
                          {Text.Features[1].FeatureBody}
                        </Typography>
                      </Typography>
                    </FeatureItem>
                  </Grid>
                </Grid>

                <Grid container rowSpacing={4}>
                  <Grid item xs={12} md={6} lg={12}>
                    <FeatureItem>
                      <img src={Text.Features[2].FeatureImage} alt={Text.Features[2].Featurename} />
                      <Typography component="article">
                        <Typography variant="h4">
                          {Text.Features[2].Featurename}
                        </Typography>
                        <Typography variant="body1">
                          {Text.Features[2].FeatureBody}
                        </Typography>
                      </Typography>
                    </FeatureItem>
                  </Grid>
                  <Grid item xs={12} mdm={6} lg={12}>
                    <FeatureItem>
                      <img src={Text.Features[3].FeatureImage} alt={Text.Features[3].Featurename} />
                      <Typography component="article">
                        <Typography variant="h4">
                          {Text.Features[3].Featurename}
                        </Typography>
                        <Typography variant="body1">
                          {Text.Features[3].FeatureBody}
                        </Typography>
                      </Typography>
                    </FeatureItem>
                  </Grid>
                </Grid>

              </ReactOwlCarousel>
            </FeatureSliderContainer>

          </Grid>
        </Grid>
      </Container>
    </CompanyInfoContainer >
  );
}

export default CompanyInfo;
