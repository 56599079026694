
import { Direction } from "../../Theme/MainDirection";
export const ServiceCategoriesOptions = {
    navText: ["", ""],
    margin: 15,
    rtl: Direction.owlRTL,
    autoplay: false,
    smartSpeed: 450,
    autoplaySpeed: 450,
    responsive: {
        // breakpoint from 0 up
        0: {
            items: 1,
        },
        // breakpoint from 480 up
        480: {
            items: 1,
        },
        // breakpoint from 768 up
        768: {
            items: 2,
            nav: false,
        },
        1280: {
            items: 3,
            nav: true,
        },
    },
};