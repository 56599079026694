import styled from "styled-components";
import Brackpoints from "./Brackpoints";

const SmallConatiner = styled.div`
  @media ${Brackpoints.device.lg} {
    & > div {
      width: 1080px;
    }
  }

`;

export default SmallConatiner;
