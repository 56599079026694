import { Direction } from "../../Theme/MainDirection";
export const mainSliderOptions = {
   
    navText:['',''],
    margin: 15,
    rtl: Direction.owlRTL,
    autoplay: false,
    smartSpeed: 450,
    autoplaySpeed: 450,

    responsive: {
        // breakpoint from 0 up
        0: {
            items: 1,
        },
        // breakpoint from 480 up
        480: {
            items: 1,
        },
        // breakpoint from 768 up
        768: {
            items: 1,
            nav: true
        },
        1280: {
            items: 2,
            nav: true
        }
    },
};