import styled from "styled-components";
import customTheme from "../../Theme/theme";
import FooterBg from "../../assets/images/footer_bg.svg";
import { Direction } from "../../Theme/MainDirection";
import Brackpoints from "../../Theme/Brackpoints";

export const FooterContainer = styled.footer`
 padding: 80px 0px 24px 0px;
@media ${Brackpoints.device.md}{
    padding: 122px 0px 84px 0px;
  min-height: 550px;

}
  background: url(${FooterBg}) top center no-repeat
    ${customTheme.palette.secondary.main};
  background-size: contain;
`;

/*/*/
export const FooterList = styled.div`
  h5 {
    margin-bottom: 18px;
  }
  & > a {
    display: block;
    text-align: ${Direction.Right};
    padding: 6px 0px 6px 0px;
    font-weight: 400;
    font-size: 0.75rem;
    &:hover {
      background: none;
      color: orange;
    }
  }
`;

/*/*/

export const SocialMediaIconsConatiner = styled.div`
  display: flex;
  a {
    background: #373e48;
    padding: 0px;
    margin: 4px 2px;
    &:hover {
      background: #0076e2;
    }
  }
`;

/*/*/
export const FooterArticle = styled.div`

   padding-top: 40px;

  @media ${Brackpoints.device.md} {
     padding-top: 0px;
   }

  p {
    margin: 10px 0px 10px 0px;
  }
  @media ${Brackpoints.device.lg} {
    ${Direction.PaddingRight}:40px;
    padding-top: 0px;
    p {
      margin: 24px 0px 20px 0px;
    }
  }


   
     



`;

/*/*/
export const CopyRightsContainer = styled.div`
  padding-top: 25px;
  margin-top: 25px;

    @media ${Brackpoints.device.md} {
       padding-top: 45px;
  margin-top: 75px;
   }
   
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`;

/*/*/
export const CopyRights = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  article {
    ${Direction.PaddingRight}:20px;
  }
`;
