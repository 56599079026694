import { Button, Container, Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import { CopyRights, CopyRightsContainer, FooterArticle, FooterContainer, FooterList, SocialMediaIconsConatiner } from "./FooterStyle";
import { NavLink as RouterLink } from "react-router-dom";
import SmallContainer from "../../Theme/SmallContainer";
import { Text } from "../../Text";
import ContactsItem from "../ContactsItem/ContactsItem";
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';


function Footer() {
    // Scroll to top
    const handelClick = () => {
        window['scrollTo']({ top: 0, behavior: 'smooth' })
    }
    return (
        <FooterContainer>
            <SmallContainer>
                <Container fixed>
                    <Grid container>
                        <Grid item xs={12} md={7}>
                            <Grid container>

                                <Grid item xs={6} sm={4}>
                                    <FooterList>
                                        <Typography variant="h5">{Text.GlobalTitles.FooterSectionsTitle}</Typography>
                                        <Button color="inherit" component={RouterLink} to="/">{Text.navLinks.home}</Button>
                                        <Button color="inherit" component={RouterLink} to="/ourClients">{Text.navLinks.ourClients}</Button>
                                        <Button color="inherit" component={RouterLink} to="/aboutUs">{Text.navLinks.aboutUs}</Button>
                                        <Button color="inherit" component={RouterLink} to="/contactUs">{Text.navLinks.contactUs}</Button>
                                    </FooterList>
                                </Grid>

                                <Grid item xs={6} sm={4}>
                                    <FooterList>
                                        <Typography variant="h5">{Text.GlobalTitles.FooterServicesTitle}</Typography>
                                        <Button color="inherit" component={RouterLink} to="/addValue">{Text.subMenu.addValue}</Button>
                                        <Button color="inherit" component={RouterLink} to="/securityPrinting">{Text.subMenu.securityPrintg}</Button>
                                        <Button color="inherit" component={RouterLink} to="/programing">{Text.subMenu.programing}</Button>
                                        <Button color="inherit" component={RouterLink} to="/networking">{Text.subMenu.networking}</Button>
                                    </FooterList>
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <FooterList>
                                        <Typography variant="h5">{Text.GlobalTitles.FooterDirectCallTitle}</Typography>
                                        <ContactsItem icon={Text.contactsItems.PhoneItem.icon} contactData={Text.contactsItems.PhoneItem.data} url={Text.contactsItems.PhoneItem.url} />
                                        <ContactsItem icon={Text.contactsItems.WhatsItem.icon} contactData={Text.contactsItems.WhatsItem.data} url={Text.contactsItems.WhatsItem.url} />
                                        <ContactsItem icon={Text.contactsItems.EmailItem.icon} contactData={Text.contactsItems.EmailItem.data} url={Text.contactsItems.EmailItem.url} />
                                    </FooterList>
                                </Grid>

                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <FooterArticle>
                                <Typography component="article">
                                    <Typography variant="h5">{Text.GlobalTitles.FooterCompanyInfoTitle}</Typography>
                                    <Typography variant="body1">{Text.CompanyInfo.CompanyBody}</Typography>
                                    <SocialMediaIconsConatiner>
                                        {Text.socialMedia.map((item, index) => {
                                            return (
                                                <div key={index}>
                                                    <IconButton component="a" href={item.url} target="_blank" rel=""><img src={item.icon} alt="icon" /></IconButton>
                                                </div>
                                            )
                                        })}
                                    </SocialMediaIconsConatiner>
                                </Typography>
                            </FooterArticle>
                        </Grid>
                    </Grid>
                    <CopyRightsContainer>
                        <Grid container sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                            <Grid item xs={12} sm="auto">
                                <CopyRights>
                                    <img src={Text.CoyRights.logo} alt="masafa" />
                                    <Typography component="article">
                                        <Typography variant="body1">{Text.CoyRights.head}</Typography>
                                        <Typography variant="body1">{Text.CoyRights.body}</Typography>
                                    </Typography>
                                </CopyRights>
                            </Grid>
                            <Grid item xs={12} sm="auto" sx={{ display: 'none', '@media(min-width:768px)': { display: 'block' } }}>
                                <IconButton onClick={handelClick} sx={{ background: '#0076E2', color: '#fff', '&:hover': { background: '#005FB7' } }}>
                                    <KeyboardArrowUpOutlinedIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </CopyRightsContainer>
                </Container>
            </SmallContainer>
        </FooterContainer>
    );
}

export default Footer;
