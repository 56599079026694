import React from "react";
import ReactOwlCarousel from "react-owl-carousel-rtl";
import { NetworkingSliderData } from "./NetworkData";
import { ServiceCategoriesConatiner } from "../ServiceCategories/ServiceCategoriesStyle";
import { ServiceCategoriesOptions } from "../ServiceCategories/ServiceCategoriesSliderOptions";

function NetworkingSlider() {
  return (
    <ServiceCategoriesConatiner>
      <ReactOwlCarousel className="owl-theme" {...ServiceCategoriesOptions}>
        {NetworkingSliderData}
      </ReactOwlCarousel>
    </ServiceCategoriesConatiner>
  );
}

export default NetworkingSlider;
