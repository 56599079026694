import { Box } from "@mui/system";
import React from "react";
import notFoundimage from "../../assets/images/PageNotFound.jpg";

const NotFound = () => {
  return (
    <div>
      <Box
        sx={{
          pt: 6,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          style={{ width: "400px", height: "auto", maxWidth: "100%" }}
          src={notFoundimage}
          alt="NOtfound"
        />
      </Box>
    </div>
  );
};

export default NotFound;
