import styled from "styled-components";
import customTheme from "../../Theme/theme";
import Brackpoints from "../../Theme/Brackpoints";

export const ServiceCategoriesContainer = styled.section`
  padding: 40px 0px;
  background-color: ${customTheme.palette.primary.light};

  /* Tabs custom style */
  .MuiTabs-flexContainer {
       button {
        padding: 22px 12px;
        
      }
    @media ${Brackpoints.device.md} {
      justify-content: center !important;
      button {
        padding: 22px 14px;
        margin-left: 20px;
        margin-right: 20px;
        font-size: 0.875rem;
      }
    }
  }
  .MuiTabs-indicator {
    background-color: #ff8a1e !important;
  }
  .MuiTab-textColorPrimary {
    //color: #000000 !important;
  }
  .Mui-selected {
    color: #ff8a1e !important;
  }
`;
/*container padding*/
export const ServiceCategoriesConatiner = styled.div`
  @media (min-width: 1200px) {
    padding: 0px 53px;
  }
`;
