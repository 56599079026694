import { Container } from "@mui/material";
import React from "react";
import styled from "styled-components";
import SmallContainer from "../../Theme/SmallContainer";
import ContentBgRight from "../../assets/images/ContentBgRight.png";
import ContentBgLeft from "../../assets/images/ContentBgLeft.png";
import Brackpoints from "../../Theme/Brackpoints";
import { motion } from "framer-motion";
const InsideMainContainer = styled.section`
@media ${Brackpoints.device.md}{
  background-image: url(${ContentBgLeft}) , url(${ContentBgRight}) ;
  background-position: center left , center right;
  background-repeat: no-repeat , no-repeat;
}

  padding-bottom: 120px;
`;
function InsideMainContent(props) {
  return (
    <InsideMainContainer>
      <motion.div initial={{opacity:0}} animate={{opacity:1}}>
      <SmallContainer>
        <Container fixed>{props.children}</Container>
      </SmallContainer>
      </motion.div>
    </InsideMainContainer>
  );
}

export default InsideMainContent;
