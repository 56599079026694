import styled from "styled-components";
import { Direction } from "../../Theme/MainDirection";
export const OurClientsContainer = styled.section`
    background-color: #fff;
    padding: 67px 0px;

`

export const ClientLogoItem = styled.div`
    height: 225px;
    display: block;
    text-align: center;
    ${Direction.BorderLeft} :1px solid #E6EBF1;
    padding-top: 24px;
    background: #fff;
    span{
        height: 120px;
        display: flex;
        
        justify-content: center;
        align-items: center;
        margin-bottom: 12px;
        img{
            
            height: auto;
        }
    }
    p{
        text-transform: uppercase;
        color:#666;
    }

    
`