import React from "react";
import ReactOwlCarousel from "react-owl-carousel-rtl";
import { addValueSliderData } from "./AddValueData";
import { ServiceCategoriesConatiner } from "../ServiceCategories/ServiceCategoriesStyle";
import { ServiceCategoriesOptions } from "../ServiceCategories/ServiceCategoriesSliderOptions";

function AddValueSlider() {
  return (
    <ServiceCategoriesConatiner>
      <ReactOwlCarousel className="owl-theme" {...ServiceCategoriesOptions}>
        {addValueSliderData}
      </ReactOwlCarousel>
    </ServiceCategoriesConatiner>
  );
}

export default AddValueSlider;
