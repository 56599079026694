import { Box } from "@mui/system";
import React from "react";
import msg from "../../assets/images/msg_send.png"

const ThankYou = () => {
    return (
        <div>
            <Box
                sx={{
                    pt: 4,
                    pb:4,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <img
                    style={{ width: "238px", height: "auto", maxWidth: "100%" }}
                    src={msg}
                    alt="ThankYou"
                />
            </Box>
        </div>
    );
};

export default ThankYou;