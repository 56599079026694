
import React from 'react';
import InsidePageTitle from '../../Components/InsidePageTitle/InsidePageTitle';
import InsidePageDescription from '../../Components/InsidePageDescription/InsidePageDescription';
import { Text } from "../../Text";
import InsideMainContent from '../../Components/InsideMainContent/InsideMainContent';
import { addValueSectionsData } from '../../Components/AddValueCategory/AddValueData';
import { Grid } from '@mui/material';

const AddValue = () => {
    return (
        <div>
            <InsidePageTitle pageName={Text.subMenu.addValue}>{Text.MainSliderData[0].cardName}</InsidePageTitle>
            <InsidePageDescription
                descriptionImage={Text.InsidePageDescription.AddValuePage.descriptionImage}
                descriptionHead={Text.InsidePageDescription.AddValuePage.descriptionHead}
                descriptionBody={Text.InsidePageDescription.AddValuePage.descriptionBody}
            />
            <InsideMainContent>
                <Grid container spacing={2}>
                    {addValueSectionsData}
                </Grid>
            </InsideMainContent>
        </div>
    )
}

export default AddValue
