import React from "react";
import ReactOwlCarousel from "react-owl-carousel-rtl";

import { Container } from "@mui/material";
import { SliderContainer } from "./MainSliderStyle";
import { mainSliderOptions } from "./MainSliderOptions";
import { MainSliderData } from "./MainSliderData";

const MainSlider = () => {
  return (
    <SliderContainer>
      <Container fixed >
        <ReactOwlCarousel  className="owl-theme" {...mainSliderOptions}>
          {MainSliderData}
        </ReactOwlCarousel>
      </Container>
    </SliderContainer>
  );
};

export default MainSlider;
