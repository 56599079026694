import { ClientLogoItem } from "./OurClientsStyle";
import { Grid, Typography } from "@mui/material";
import { Text } from "../../Text";

const ClientsLogosData = Text.clientsLogos.map((item, index) => {
  return (
    <Grid item xs={12} sm={6} lg={3} key={index}>
      <ClientLogoItem>
        <span>
          <img src={item.clientLogoImage} alt={item.clientLogoName} />
        </span>
        <Typography component="article">
          <Typography variant="h5">{item.clientLogoName}</Typography>
          <Typography variant="body1">{item.clientLogobody}</Typography>
        </Typography>
      </ClientLogoItem>
    </Grid>
  );
});

export default ClientsLogosData