import React from "react";
import { Container } from "@mui/material";
import { ServiceCategoriesContainer } from "./ServiceCategoriesStyle";
import SmallContainer from "../../Theme/SmallContainer";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Text } from "../../Text";

import AddValueSlider from "../AddValueCategory/AddValueSlider";
import SecurtyPrintingSlider from "../SecurtyPrintingCategory/SecurtyPrintingSlider";
import ProgramingSlider from "../ProgramingCategory/ProgramingSlider";
import NetworkingSlider from "../NetworkCategory/NetworkSlider";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: "40px" }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

// function a11yProps(index) {
//     return {
//         id: `simple-tab-${index}`,
//         'aria-controls': `simple-tabpanel-${index}`,
//     };
// }

/*/////////////////////////////////////////////////////////////////////*/

const ServiceCategories = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <ServiceCategoriesContainer>
      <SmallContainer>
        <Container fixed>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab label={Text.subMenu.addValue} />
                <Tab label={Text.subMenu.securityPrintg} />
                <Tab label={Text.subMenu.programing} />
                <Tab label={Text.subMenu.networking} />
              </Tabs>
            </Box>

            <TabPanel value={value} index={0}>
              <AddValueSlider />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <SecurtyPrintingSlider />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <ProgramingSlider />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <NetworkingSlider />
            </TabPanel>
          </Box>
        </Container>
      </SmallContainer>
    </ServiceCategoriesContainer>
  );
};

export default ServiceCategories;
