import { Button, CardMedia, Grid, TextField, Typography } from "@mui/material";
import React from "react";
import InsideMainContent from "../../Components/InsideMainContent/InsideMainContent";
import InsidePageDescription from "../../Components/InsidePageDescription/InsidePageDescription";
import InsidePageTitle from "../../Components/InsidePageTitle/InsidePageTitle";
import { Text } from "../../Text";
import styled from "styled-components";
import Brackpoints from "../../Theme/Brackpoints";
import { Direction } from "../../Theme/MainDirection";
import ContactsItem from "../../Components/ContactsItem/ContactsItem";
const MapContainer = styled.article`
  padding: 0px;
  p {
    padding: 10px 0px;
    color: #555;
  }
  @media ${Brackpoints.device.lg} {
    ${Direction.PaddingLeft}:50px;
  }
`;

const ContactUs = () => {
  return (
    <div>
      <InsidePageTitle pageName={Text.navLinks.contactUs}>
        {Text.InsidePageTitles.ContactUsPage.TitleName}
      </InsidePageTitle>
      <InsidePageDescription
        descriptionImage={
          Text.InsidePageDescription.ContactUsPage.descriptionImage
        }
        descriptionHead={
          Text.InsidePageDescription.ContactUsPage.descriptionHead
        }
        descriptionBody={
          Text.InsidePageDescription.ContactUsPage.descriptionBody
        }
      />
      <InsideMainContent>
        <Grid container>
          <Grid item xs={12} md={6}>
            <MapContainer component="article">
              <CardMedia
                component="iframe"
                sx={{
                  border: 0,
                  height: "202px",
                  borderRadius: 2,
                  width: "100%",
                  background: "#eee",
                }}
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3351.6664724533184!2d13.0684195!3d32.8540815!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13a8efbf236f2819%3A0xf21c853488ddce82!2sMasafa%20For%20IT%20Development!5e0!3m2!1sen!2seg!4v1632970426680!5m2!1sen!2seg"
                allowfullscreen="true"
                loading="lazy"
              />

              <Typography variant="body1">
                {Text.contactsItems.Location.name}
              </Typography>
            </MapContainer>

            <Grid container spacing={0.5} mt={3}>
              <Grid item xs={12} mb={2}>
                <Typography variant="h5">{Text.GlobalTitles.FooterDirectCallTitle}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <ContactsItem
                  icon={Text.contactsItems.PhoneItem.icon}
                  contactData={Text.contactsItems.PhoneItem.data}
                  url={Text.contactsItems.PhoneItem.url}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <ContactsItem
                  icon={Text.contactsItems.FaxItem.icon}
                  contactData={Text.contactsItems.FaxItem.data}
                  lable={Text.contactsItems.FaxItem.lable}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <ContactsItem
                  icon={Text.contactsItems.WhatsItem.icon}
                  contactData={Text.contactsItems.WhatsItem.data}
                  url={Text.contactsItems.WhatsItem.url}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <ContactsItem
                  icon={Text.contactsItems.BoxItem.icon}
                  contactData={Text.contactsItems.BoxItem.data}
                  lable={Text.contactsItems.BoxItem.lable}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            {/* info@masafa.ly */}
            <form action="https://formsubmit.co/info@masafa.ly" method="POST">
              <TextField type="hidden" name="_template" value="box" sx={{ display: 'none' }} />
              <TextField type="hidden" name="_captcha" value="false" sx={{ display: 'none' }} />
              <TextField type="hidden" name="_next" value="https://www.masafa.ly/thankYou" sx={{ display: 'none' }} />
              <TextField type="hidden" name="_honey" sx={{ display: 'none' }} />


              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField label={Text.ContactFormLabel.name} variant="outlined" type="text" name="name" fullWidth required />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField id="outlined-basic" label={Text.ContactFormLabel.email} variant="outlined" type="email" name="email" fullWidth required />
                </Grid>
                <Grid item xs={12}>
                  <TextField label={Text.ContactFormLabel.subject} variant="outlined" name="_subject" type="text" fullWidth required />
                </Grid>
                <Grid item xs={12}>
                  <TextField label={Text.ContactFormLabel.message} variant="outlined" type="text" name="message" fullWidth multiline
                    rows={8} required />
                </Grid>
                <Grid item xs={12}>
                  <Button variant="contained" disableElevation="true" color="secondary" type="submit">{Text.ContactFormLabel.button}</Button>
                </Grid>
              </Grid>


            </form>

          </Grid>
        </Grid>
      </InsideMainContent>
    </div>
  );
};

export default ContactUs;
