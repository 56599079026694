import styled from "styled-components";
import { Direction } from "../../Theme/MainDirection";
import Brackpoints from "../../Theme/Brackpoints";
export const CompanyInfoContainer = styled.section`
  background-color: #fff;
  padding: 20px 0px;
  @media ${Brackpoints.device.lg} {
    padding: 50px 0px 99px 0px;
    &>div{
      ${Direction.PaddingRight}: 38px;
    }
  }
`;

export const InfoBox = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom:40px;
  @media ${Brackpoints.device.md} {
    margin-bottom:0px;
  }

  img {
    display: none;
    @media ${Brackpoints.device.md} {
      display: block;
    }
  }

  article {
    p{
      line-height: 24px;
      color:#555;
      margin-bottom: 20px;
    }

    ${Direction.PaddingRight}:0px;

    @media ${Brackpoints.device.md} {
      ${Direction.PaddingRight}:20px;
    }
    @media ${Brackpoints.device.lg} {
      ${Direction.PaddingRight}:50px;
    }

    h6 {
      margin: 9px 0px 14px 0px;
    }

    @media ${Brackpoints.device.lg} {
      width: 600px;
    }

  }
`;

/*/*/
export const FeatureItem = styled.div`
  display: flex;
  align-items: flex-start;
  @media ${Brackpoints.device.lg} {
      width: 332px;
  }
    img{
      width: 67px !important;
      

    }
  article {
    ${Direction.PaddingRight}:17px;

    p{
      color:#555;
      padding-top: 6px;
    }
  }

`
/*/*/

export const FeatureSliderContainer = styled.div`

.owl-theme .owl-dots {
  text-align: ${Direction.Right};
}


`
