import { createGlobalStyle } from "styled-components";
import styled from "styled-components";
import logo_png from '../../assets/images/logo_png.png';
import logo_svg from '../../assets/images/logo_svg.svg';
import { Direction } from "../../Theme/MainDirection";
import Brackpoints from '../../Theme/Brackpoints';
import customTheme from "../../Theme/theme";

import dropDownicon_1 from '../../assets/images/sub_1.svg'
import dropDownicon_2 from '../../assets/images/sub_2.svg'
import dropDownicon_3 from '../../assets/images/sub_3.svg'
import dropDownicon_4 from '../../assets/images/sub_4.svg'

// NavBarContainer
export const NavBarContainer = styled.header`

    @media ${Brackpoints.device.lg}{
        &>div{
            padding-left: 50px;
            padding-right: 50px;
            
        }
    }

`;

// Logo
export const Logo = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
     
    article{
         ${Direction.PaddingRight} :19.2px;
        h1{
            font-size: 1.5rem;
            font-weight: 700;
        }
        p{
            font-size: 0.75rem;
            margin-top: -4px;
            color:#666;
        }
    }

    
`;


// LogoIcon
export const LogoIcon = styled.div`

    @media ${Brackpoints.device.xs} {
        background-image: url(${logo_svg});
    }
    @media ${Brackpoints.device.md} {
        background-image: url(${logo_png});
    }

    width: 46.82px;
    height: 49.49px;
    margin-top: -4px;
   
`;

// NavBarGlobalStyle
export const NavBarGlobalStyle = createGlobalStyle`
    .normalNav{
        padding-top: 36px;
        padding-bottom: 36px;
        background-color: #fff;
        
    }
    .fixedNav{
        padding-top:10px;
        padding-bottom: 10px;
        background-color: #fff;
        width:100%;
        position: fixed;
        top: 0;
        box-shadow: 0px 2px 16px rgba(0,0,0,0.1);
        transition: all 0.2s ease-in-out;
        z-index: 10;
    }

    .navLinksContainer{
        width: 550px;
        display: none;
        justify-content: space-between;
        @media ${Brackpoints.device.lg}{
            display: flex;
        }

        button ,a{
            font-size: 0.875rem;
            font-weight: 500;
           &:hover{
               color:orange;
               background-color: #fff;
           } 
        }
        .active{
             color:orange;
             position: relative;
             &::after{
                content: "";
                width: 14px;
                height: 1px;
                background: #000;
                position: absolute;
                bottom: 0;
                ${Direction.Right}:0;
                ${Direction.MarginRight}:9px;
             }
        }
        /*/*/
        .lang_icon{
            font-weight: 400 !important;
            color:red;
        }
        
    }

    /*Open SideMenu button*/
    .menuButton{
         @media ${Brackpoints.device.lg}{
            display: none;
        }
    }

    /*/*/
    .subMenuItem{
        display: flex;
        width: 100%;
        align-items: center;
       

        article{
           width: calc(100% - 52px);
            ${Direction.PaddingRight}:19px;
        }
        h6{
            font-size: 0.875rem;
            font-weight: 500;
            color: #000;
        }
        p{
            font-size: 0.7rem;
            padding-bottom: 2px;
            color:#999;
        }

                .dropIco{
            width: 52px;
            height: 52px;
            display: block;
        }
        .dropDownicon_1{
             background-image: url(${dropDownicon_1});
        }
        .dropDownicon_2{
             background-image: url(${dropDownicon_2});
        }
        .dropDownicon_3{
             background-image: url(${dropDownicon_3});
        }
        .dropDownicon_4{
             background-image: url(${dropDownicon_4});
        }
        
    }


    /*/*/

    .sideMenu{
        width: 280px;
        max-width: 100%;
        a{
            width: 100%;
        }
         span{
            font-size: 0.875rem;
            font-weight: 500;
            
        }
        a.active{
             
            background:${customTheme.palette.primary.main};
           
        }
        
         
    }

`

export const DropMenuStyle = {
    overflow: 'visible',
    filter: 'drop-shadow(0px 10px 28px rgba(0,0,0,23%))',
    padding: 2,

    mt: 2,
    width: 291,
    minHeight: 150,
    borderRadius: 3,
    '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: 0,
        left: 14,
        width: 10,
        height: 10,
        bgcolor: 'background.paper',
        transform: 'translateY(-50%) rotate(45deg)',
        zIndex: 0,
    }
}