
import { createTheme } from "@mui/material";
// page Direction

import { Direction } from "./MainDirection";
const pageBody = document.querySelector('body');
pageBody.setAttribute('dir', Direction.Dir)

const customTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1280, // from pc 1366 to more
      xl: 1280  // same width for large screen if you want
    }
  },

  //Page Dir
  direction: Direction.Dir,

  //Fonts
  typography: {
    fontFamily: "expo-arabic",
    h1: {
      fontSize: "1.625rem",
      fontWeight: "700",
      lineHeight: "38px",

    },

    h2: {
      fontSize: "1.375rem",
      fontWeight: "700",

    },

    h3: {
      fontSize: "1.125rem",
      fontWeight: "600",
      lineHeight:"28px"

    },

    h4: {
      fontSize: "1rem",
      fontWeight: "600",

    },

    h5: {
      fontSize: "0.875rem",
      fontWeight: "600",

    },

    h6: {
      fontSize: "1.125rem",
      fontWeight: "500",

    },

    body1: {
      fontSize: "0.75rem",
      fontWeight: "400",
      lineHeight: "22px",

    },

  },

  // //theme Colors
  palette: {

    //mode: 'light',
    primary: {
      light: "#C9E9F6",
      main: "#9DD7EF",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      //light: "#0066ff",
      main: "#FCE991",
      // dark: will be calculated from palette.secondary.main,
      //contrastText: "#ffcc00",  // TextColors
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
});

export default customTheme;




