import React from 'react';
import { Container, Grid, Typography } from '@mui/material';
import SmallContainer from "../../Theme/SmallContainer";
import styled from "styled-components"
import { Text } from '../../Text';
import { motion } from 'framer-motion';
const VisionBox = styled.div`
    article{
        p{
            padding-top: 10px;
            width: 272px;
            max-width: 100%;
            color:#555
        }
    }
`
function VisionAndMission() {
    return (
        <div>
            <motion.div
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.5, duration: 0.4, type: "tween" }}
            >
                <SmallContainer>
                    <Container fixed>
                        <Grid container spacing={2}>
                            {Text.VisionAndMission.map((item, index) => {
                                return (
                                    <Grid item xs={12} sm={6} md={4} key={index}>
                                        <VisionBox>
                                            <Typography component="article">
                                                <Typography variant="h4">{item.title}</Typography>
                                                <Typography variant="body1">{item.body}</Typography>
                                            </Typography>
                                        </VisionBox>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Container>
                </SmallContainer>
            </motion.div>
        </div>
    )
}

export default VisionAndMission
