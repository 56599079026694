import React from "react";
import { NavLink as RouterLink } from "react-router-dom";
import { Button, Container, Grid } from "@mui/material";
import SmallContainer from "../../Theme/SmallContainer";
import { OurClientsContainer } from "./OurClientsStyle";
import BoxTitle from "../BoxTitle/BoxTitle";
import { Text } from "../../Text";
import { Box } from "@mui/system";
import ClientsLogosData from "./OurClientsData";
import ControlPointIcon from "@mui/icons-material/ControlPoint";

function OurClients() {
    return (
        <OurClientsContainer>
            <Box sx={{ textAlign: "center" }}>
                <BoxTitle>{Text.GlobalTitles.OurClientsTitle}</BoxTitle>
            </Box>
            <SmallContainer>
                <Container fixed>
                    <Grid
                        container
                        spacing={0}
                        sx={{ border: "1px solid #E6EBF1", borderRight: "0px" }}
                    >
                        {ClientsLogosData}
                    </Grid>
                    <Box sx={{ textAlign: "center", paddingTop:"30px" }}>
                        <Button
                            component={RouterLink}
                            to="/ourClients"
                            sx={{
                                fontWeight: 500,
                                textDecoration: "underline",
                                fontSize: "0.75rem",
                            }}

                            color="inherit"
                            startIcon={<ControlPointIcon sx={{ color: "#0076E2" }} />}
                        >
                            {Text.GlobalButtons.ViewAll}
                        </Button>
                    </Box>
                </Container>
            </SmallContainer>
        </OurClientsContainer>
    );
}

export default OurClients;
