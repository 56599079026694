import * as React from 'react';
import './Theme/MainStyle';
import ScrollTop from './Theme/ScrollTop';
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// Components --------------------------------------
import NavBar from './Components/NavBar/NavBar';
// Pages -------------------------------------------
import Home from './Pages/Home/Home';
import AddValue from './Pages/AddValue/AddValue';
import SecurityPrinting from './Pages/SecurityPrinting/SecurityPrinting';
import Programing from './Pages/Programing/Programing';
import Networking from './Pages/Networking/Networking';
import OurClients from './Pages/OurClients/OurClients';
import AboutUs from './Pages/AboutUs/AboutUs';
import ContactUs from './Pages/ContactUs/ContactUs';
import NotFound from './Pages/NotFound/NotFound';

// Custom Theme
import { ThemeProvider } from '@mui/material';
import customTheme from './Theme/theme';

// RtL Dir
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import Footer from './Components/Footer/Footer';
import ThankYou from './Pages/ThankYou/ThankYou';
const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [rtlPlugin],
});


function App() {
  return (
    <ThemeProvider theme={customTheme}>
      <CacheProvider value={cacheRtl}>
        <Router>
          <ScrollTop />
          <NavBar />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/addValue" component={AddValue} />
            <Route path="/securityPrinting" component={SecurityPrinting} />
            <Route path="/programing" component={Programing} />
            <Route path="/networking" component={Networking} />
            <Route path="/ourClients" component={OurClients} />
            <Route path="/aboutUs" component={AboutUs} />
            <Route path="/contactUs" component={ContactUs} />
            <Route path="/thankYou" component={ThankYou} />
            <Route component={NotFound} />
          </Switch>
          <Footer />
        </Router>
      </CacheProvider>
    </ThemeProvider>
  );
}

export default App;
